import Debug from 'debug';

import { selectUserCountry } from '~/app/lib/store/session/selectors';
import { refreshAlbumApi } from '~/app/lib/songwhipApi/albums';
import { Dispatch, State } from '~/app/lib/store/types';

import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';

import { fetchAlbumError, fetchAlbumStart } from './fetchAlbum';

const debug = Debug('songwhip/store/albums/actions');

export default ({ albumId }: { albumId: number }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    debug('refresh: %s', albumId);

    if (!albumId) throw new Error('`albumId` required');

    dispatch(fetchAlbumStart(albumId));

    try {
      const country = selectUserCountry(getState());
      const payload = await refreshAlbumApi({ albumId, country });
      dispatchFetchItemSuccess(dispatch, payload);
    } catch (e) {
      const error = e as Error;
      dispatch(fetchAlbumError(albumId, error));
    }
  };
