import { useMemo, useRef } from 'react';

import localizeAlbum from '~/app/lib/store/albums/actions/localizeAlbum';
import refreshAlbum from '~/app/lib/store/albums/actions/refreshAlbum';
import { selectUserIsBot } from '~/app/lib/store/session/selectors';
import { useSelector, useDispatch } from '~/app/lib/store/redux';
import { useAppLoading } from '~/app/components/NextApp/lib/CoreUi';
import { SelectedAlbum } from '~/app/lib/store/albums/types';

/*
 * A react hook that takes care of common item page checks
 */
export default (album: SelectedAlbum) => {
  const didRefreshRef = useRef(false);
  const didLocalizeRef = useRef(false);
  const userIsBot = useSelector(selectUserIsBot);
  const dispatch = useDispatch();

  // show the app loading indicator whenever the album is in a 'loading' state
  useAppLoading()(album.isLoading);

  // useMemo() over useEffect() to trigger isLoading state asap
  // otherwise when the album isShallow and needs to fetch data from songwhip-api
  // before rendering we see a flash of shallow album before loading state is
  // rendered. useMemo() is run sync on first render pass.
  useMemo(() => {
    // this code is meant for client only
    if (!process.browser) return;

    if (album.isLoading) return;

    // don't make any api requests if user is bot to save api quotas
    if (userIsBot) return;

    // we don't refresh or localize prereleases as they
    // don't have any store links yet
    if (album.isPrerelease || album.isDraft) return;

    if (
      !album.hasLinksForUserCountry &&
      !album.isStale &&
      !didLocalizeRef.current
    ) {
      dispatch(localizeAlbum({ albumId: album.id }));

      // flag that localize was attempted to prevent infinite loop if api request fails
      didLocalizeRef.current = true;

      return;
    }

    if (album.isStale && !didRefreshRef.current) {
      dispatch(refreshAlbum({ albumId: album.id }));

      // flag that refresh was attempted to prevent infinite loop if api request fails
      didRefreshRef.current = true;
    }
  }, [album]);
};
