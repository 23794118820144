import removeUndefinedKeys from '../removeUndefinedKeys';
import toImage from './toImage';

export const toPerson = ({
  name,
  description = undefined,
  links,
  imageUrl,
  baseUrl,
}) =>
  name && {
    '@type': 'Person',
    '@id': `${baseUrl}#person/${name}`,
    name,
    image: toImage({
      url: imageUrl,
      title: name,
    }),
    description,
    sameAs: links,
  };

export const toAlbum = ({ name, url, image, genre, subjectOfId, artistId }) =>
  name &&
  removeUndefinedKeys({
    '@type': 'MusicAlbum',
    '@id': `${url}/#album`,
    name,
    url,
    genre,
    image,
    subjectOf: subjectOfId && {
      '@id': subjectOfId,
    },
    byArtist: artistId && {
      '@id': artistId,
    },
  });

export const toArticle = ({
  title,
  date,
  keywords,
  url,
  author,
  webPageId,
  imageId,
  organizationId,
}) =>
  // articles require an author (according to google)
  author &&
  removeUndefinedKeys({
    '@type': 'Article',
    '@id': `${url}/#article`,
    isPartOf: {
      '@id': webPageId,
    },
    url,
    author,
    headline: title,
    datePublished: date && new Date(date).toISOString(),
    // TODO: make this unique
    dateModified: date && new Date(date).toISOString(),
    mainEntityOfPage: webPageId && {
      '@id': webPageId,
    },
    publisher: organizationId && {
      '@id': organizationId,
    },
    image: imageId && {
      '@id': imageId,
    },
    keywords: keywords && keywords.join(' '),
  });
