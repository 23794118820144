import { Dispatch } from 'redux';

import { selectUserCountry } from '~/app/lib/store/session/selectors';
import { State } from '~/app/lib/store/types';
import { localizeAlbumApi } from '~/app/lib/songwhipApi/albums';

import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';
import { fetchAlbumError, fetchAlbumStart } from './fetchAlbum';

export default ({ albumId }: { albumId: number }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const country = selectUserCountry(state);

    if (!country) throw new Error('user country undefined');

    dispatch(fetchAlbumStart(albumId));

    try {
      const payload = await localizeAlbumApi({ albumId, country });
      dispatchFetchItemSuccess(dispatch, payload);
    } catch (error) {
      dispatch(fetchAlbumError(albumId, error));
    }
  };
