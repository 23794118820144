import { useCallback } from 'react';

import { patchCustomPage } from '~/app/lib/store/customPages/actions/patch';
import { patchAlbum } from '~/app/lib/store/albums/actions/patch';
import { useTracker } from '~/app/lib/tracker/useTracker';
import { useDispatch } from '~/app/lib/store/redux';
import { useAppRouter } from '~/app/lib/router2';
import { useI18n } from '~/app/lib/i18n';
import { ItemTypes } from '~/types';

import {
  useAppConfirm,
  useAppToast,
} from '~/app/components/NextApp/lib/CoreUi';
import { toDraftItemPagePath } from './utils';

const useUnpublishPage = (itemType: ItemTypes) => {
  const { trackEvent } = useTracker();
  const appConfirm = useAppConfirm();
  const appRouter = useAppRouter();
  const appToast = useAppToast();
  const dispatch = useDispatch();
  const { t } = useI18n();

  return useCallback(
    async ({
      pagePath,
      itemId,
      itemName,
    }: {
      pagePath: string;
      itemId: number;
      itemName: string;
    }) => {
      appConfirm({
        content: t('item.confirmUnpublishPage'),
        actionText: t('item.unpublishPageAction'),

        onConfirm: async () => {
          switch (itemType) {
            case ItemTypes.ALBUM:
              await dispatch(
                patchAlbum({
                  albumId: itemId,
                  changes: {
                    isDraft: true,
                  },
                })
              );

              break;
            case ItemTypes.CUSTOM_PAGE:
              await dispatch(
                patchCustomPage({
                  customPageId: itemId,
                  changes: {
                    isDraft: true,
                  },
                })
              );

              break;
            default:
              throw new Error(`Unsupported item type: ${itemType}`);
          }

          appRouter.replace(toDraftItemPagePath(pagePath));

          appToast({
            text: t('item.events.pageUnpublished'),
          });

          trackEvent({
            type: 'unpublish-live-page',

            itemId,
            itemType,
            itemName,
          });
        },
      });
    },
    []
  );
};

export default useUnpublishPage;
