import React, { FC } from 'react';

import Text from '~/app/components/Text';

const ItemPageHeaderContent: FC<{
  title: string;
  subtitle: string | JSX.Element;
}> = ({ title, subtitle }) => {
  return (
    <Text
      tag="h1"
      size={resolveFontSize(title)}
      padding="0.2em 0 0"
      pointerEvents="all"
    >
      <Text
        weight="bold"
        letterSpacing={0.01}
        color="#eee"
        shadow={'rgba(0,0,0,0.4) 0 0 0.2em'}
        withEllipsis
        lineHeight="1.3em"
        centered
      >
        {title}
      </Text>

      {subtitle && (
        <Text
          size="0.87em"
          // margin="0.15em 0 0"
          color="#eee"
          shadow={'rgba(0,0,0,0.55) 0 0 0.2em'}
          withEllipsis
          lineHeight="1.2em"
          letterSpacing={0.015}
          weight="medium"
          centered
        >
          {subtitle}
        </Text>
      )}
    </Text>
  );
};

const resolveFontSize = (title: string) => {
  const { length } = title;

  if (length < 26) return '1.6rem';
  else if (length < 30) return '1.5rem';

  return '1.4rem';
};

export default ItemPageHeaderContent;
