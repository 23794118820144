import BalancedText from '~/app/components/BalancedText';
import Text from '~/app/components/Text';
import { toRgba } from '~/app/lib/utils/color';
import { usePageTheme } from './ItemPageEdit/addons/theme/PageThemeContext';

const IS_LONG_THRESHOLD = 22;

interface ItemPageHeroContentProps {
  title?: string;
  subtitle?: string | JSX.Element;
  padding?: string;
}

const ItemPageHeroContent: React.FC<ItemPageHeroContentProps> = ({
  title,
  subtitle,
  padding,
}) => {
  const pageTheme = usePageTheme();

  const shouldRender = title;
  if (!shouldRender) return null;

  const textIsLong = title!.length > IS_LONG_THRESHOLD;
  const minFontSize = textIsLong ? '3.8rem' : '4.2rem';
  const maxFontSize = textIsLong ? '4.6rem' : '5rem';
  const baseSize = textIsLong ? '5vw' : '7vw';

  return (
    <Text
      tag="h1"
      size={baseSize}
      minSize={minFontSize}
      maxSize={maxFontSize}
      maxWidth="14em"
      isCentered
      padding={padding}
    >
      {title && (
        <BalancedText
          testId="title"
          bold
          centered
          size="inherit"
          lineClamp={2}
          isExpectedToWrap={textIsLong}
          padding="0 0 0.15em"
          // lineHeight="1.5em"
          margin="-0.2em 0 -0.15em"
          shadow={`${toRgba(
            pageTheme.backgroundColor,
            0.1
          )} 0 0 0.05em,${toRgba(
            pageTheme.backgroundColor,
            0.2
          )} 0 0.01em 0.1em`}
          color={pageTheme.textColor}
          text={title}
        />
      )}
      {subtitle && (
        <Text
          size="0.62em"
          // margin="0.18em 0 0"
          padding="0 0 0.04em"
          testId="subtitle"
          color={pageTheme.textColor}
          lineHeight="1.3em"
          withEllipsis
          shadow={`${toRgba(pageTheme.backgroundColor, 0.3)} 0px 0px 0.05em`}
          centered
        >
          {subtitle}
        </Text>
      )}
    </Text>
  );
};

export default ItemPageHeroContent;
