import { deleteCustomPageApi } from '~/app/lib/songwhipApi/customPages';
import { ItemTypes } from '~/types';

import { deleteItemSuccess } from '../../lib/deleteItemSuccess';
import { Dispatch } from '../../types';

const deleteCustomPageAction =
  (customPageId: number) => async (dispatch: Dispatch) => {
    const customPage = await deleteCustomPageApi(customPageId);

    dispatch(
      deleteItemSuccess({
        id: customPageId,
        type: ItemTypes.CUSTOM_PAGE,
        path: customPage.path,
      })
    );
  };

export default deleteCustomPageAction;
