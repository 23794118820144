import toWebPage from '~/app/lib/utils/toStructuredData/toWebPage';
import toArtist from '~/app/lib/utils/toStructuredData/toArtist';
import toImage from '~/app/lib/utils/toStructuredData/toImage';

export default ({
  website,
  toBreadcrumb,
  title,
  description,
  artistName,
  artistPagePath,
  artistImageUrl,
  customPageName,
  baseUrl,
  imageUrl,
  pagePath,
  pageUrl,
}) => {
  if (!customPageName) return;

  const primaryImage = toImage({
    url: imageUrl,
    title: artistName,
  });

  const breadcrumb = toBreadcrumb([
    {
      name: artistName,
      pagePath: artistPagePath,
    },
    {
      name: customPageName,
      pagePath,
    },
  ]);

  const artistImage = toImage({
    url: artistImageUrl,
    title: artistName,
  });

  const artist = toArtist({
    name: artistName,
    url: `${baseUrl}${artistPagePath}`,
    imageId: artistImage && artistImage['@id'],
  });

  const webPage = toWebPage({
    url: pageUrl,
    title,
    description,
    primaryImageId: primaryImage && primaryImage['@id'],
    breadcrumbId: breadcrumb['@id'],
    websiteId: website['@id'],
  });

  return [primaryImage, breadcrumb, webPage, artist];
};
