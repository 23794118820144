import { useMemo } from 'react';

import {
  ItemContext,
  ResolvedPageSection,
} from '~/app/components/ItemPage/types';
import { resolvePageSection } from '~/app/components/ItemPage/SectionRenderer';
import useSelectArtist from '~/app/lib/hooks/useSelectArtist';

import useArtistItemContext from '../useArtistItemContext';

/**
 * Creates a list of PageSections from the parent Artist layout
 * that are marked as 'shared'. The artist layout context is
 * then attached to the PageSection so that it can be rendered
 * out-of-context by PageSectionRenderer.
 */
const useSharedArtistComponents = (
  artistId: number | undefined
): ItemContext['shared'] => {
  const artist = useSelectArtist(artistId);
  const itemContext = useArtistItemContext({ artist });

  const sharedComponents = useMemo(() => {
    if (!itemContext) return;

    const result: Record<string, ResolvedPageSection> = {};

    itemContext.layout.main.forEach((pageSection) => {
      // resolve layout item so presets are applied
      // as presets can contain the shareId
      const resolved = resolvePageSection({
        pageSection,
        itemContext,
      });

      // skip components that aren't flagged as 'shared'
      if (!resolved?.shareId) return;

      // bundle up the resolved PageSection including layoutData
      result[resolved.shareId] = {
        ...resolved,

        props: {
          ...resolved.props,
          isShared: true,
        },
      };
    });

    return result;
  }, [itemContext]);

  return sharedComponents;
};

export default useSharedArtistComponents;
