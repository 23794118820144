import dynamic from 'next/dynamic';
import { toObservedLoadingComponent } from '~/app/components/ItemPage/LoadingObserver';

import { PageSectionTypes } from '~/app/components/ItemPage/sections/types';

const PresaveButtonsDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/PresaveButtons'),
  { ssr: true, loading: toObservedLoadingComponent() }
);

const PresaveButtons2Dynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/PresaveButtons2'),
  { ssr: true }
);

const VideosSectionDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/VideosSection')
);

const MerchSectionDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/MerchSection'),
  {
    loading: toObservedLoadingComponent(),
  }
);

const ShowsSectionDynamic = dynamic(
  () => import('~/app/components/ItemPage/sections/ShowsSection'),
  {
    loading: toObservedLoadingComponent(),
  }
);

// The album page is the only ItemPage that uses PresaveButtons,
// so we pass them in as 'extra' layoutComponents to avoid
// bloating other ItemPage bundles
export const ALBUM_PAGE_SECTION_COMPONENTS = {
  [PageSectionTypes.VIDEOS]: VideosSectionDynamic,
  [PageSectionTypes.PRESAVE_BUTTONS]: PresaveButtonsDynamic,
  [PageSectionTypes.PRESAVE_BUTTONS_2]: PresaveButtons2Dynamic,
  [PageSectionTypes.MERCH]: MerchSectionDynamic,
  [PageSectionTypes.SHOWS]: ShowsSectionDynamic,
};
