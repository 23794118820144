import { selectCustomPageGlobal } from '~/app/lib/store/customPages/selectors';
import { useSelector } from '~/app/lib/store/redux';
import { State } from '~/app/lib/store/types';

const useSelectCustomPage = (customPageId: number) => {
  return useSelector((state: State) =>
    selectCustomPageGlobal(state, customPageId)
  );
};

export default useSelectCustomPage;
