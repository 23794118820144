import { useSelector, shallowEqual } from '~/app/lib/store/redux';

import { selectArtistValue } from '~/app/lib/store/artists/selectors';
import { MappedArtist } from '../../songwhipApi/mapper';
import { State } from '~/app/lib/store/types';

const useSelectArtists = (artistIds: number[]) => {
  const artists = useSelector(
    (state: State) =>
      artistIds
        .map((artistId) => selectArtistValue(state, artistId))
        .filter(Boolean) as MappedArtist[],

    // shallow equal used to prevent new array instance being
    // returned every time when contents are unchanged
    shallowEqual
  );

  // don't return empty arrays to make upstream conditionals simpler
  return artists.length ? artists : undefined;
};

export default useSelectArtists;
