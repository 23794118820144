const measure = <Result>(
  name: string,
  task: () => Promise<Result> | Result
) => {
  const canMeasure =
    process.browser &&
    window.performance &&
    !!performance.mark &&
    !!performance.measure;

  // just run the task if env not capable
  if (!canMeasure) {
    return task();
  }

  performance.mark(`${name}Start`);
  const result = task();

  if (!(result instanceof Promise)) {
    return result;
  }

  return result.then((result) => {
    performance.mark(`${name}End`);
    performance.measure(name, `${name}Start`, `${name}End`);

    return result;
  });
};

export default measure;
