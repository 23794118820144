import toWebPage from '~/app/lib/utils/toStructuredData/toWebPage';
import toArtist from '~/app/lib/utils/toStructuredData/toArtist';
import toImage from '~/app/lib/utils/toStructuredData/toImage';
import { toAlbum } from '~/app/lib/utils/toStructuredData';

export default ({
  website,
  toBreadcrumb,
  title,
  description,
  artistName,
  artistPagePath,
  artistImageUrl,
  albumName,
  pagePath: albumPagePath,
  imageUrl: albumImageUrl,
  pageUrl: albumPageUrl,
  baseUrl,
}) => {
  if (!artistName) return;

  const primaryImage = toImage({ url: albumImageUrl, title });

  const breadcrumb = toBreadcrumb([
    {
      name: artistName,
      pagePath: artistPagePath,
    },
    {
      name: albumName,
      pagePath: albumPagePath,
    },
  ]);

  const artistImage = toImage({ url: artistImageUrl, title: artistName });

  const artist = toArtist({
    name: artistName,
    url: `${baseUrl}/${artistPagePath}`,
    imageId: artistImage && artistImage['@id'],
  });

  const webPage = toWebPage({
    url: albumPageUrl,
    title,
    description,
    primaryImageId: primaryImage && primaryImage['@id'],
    breadcrumbId: breadcrumb['@id'],
    websiteId: website['@id'],
  });

  const album = toAlbum({
    url: albumPageUrl,
    name: albumName,
    image: primaryImage,
    // TODO: add genre
    genre: undefined,
    subjectOfId: webPage['@id'],
    artistId: artist!['@id'],
  });

  return [primaryImage, breadcrumb, webPage, artist, album];
};
