import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';
import { patchCustomPageApi } from '~/app/lib/songwhipApi/customPages';

import { fetchCustomPageError, fetchCustomPageStart } from './fetchCustomPage';
import { Dispatch } from '../../types';

export const patchCustomPage =
  (params: Parameters<typeof patchCustomPageApi>[0]) =>
  async (dispatch: Dispatch) => {
    const { customPageId } = params;

    try {
      dispatch(fetchCustomPageStart(customPageId));
      const album = await patchCustomPageApi(params);
      dispatchFetchItemSuccess(dispatch, album);
    } catch (error) {
      dispatch(fetchCustomPageError(customPageId, error));
      throw error;
    }
  };
