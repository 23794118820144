import { FC, useEffect, useRef } from 'react';

// npm module isn't transpiled so we're importing locally
import balanceText from './lib/balanceText';

import Text, { TextProps } from '~/app/components/Text';
import measure from '~/app/lib/utils/measure';

interface BalancedTextProps extends Omit<TextProps, 'children'> {
  text: string;

  /**
   * Context signals if the text is likely to wrap, usually using a text length
   * threshold, then we can decide whether to hide the text initially before
   * balance text has run to hide FOUC.
   */
  isExpectedToWrap: boolean;
}

const BalancedText: FC<BalancedTextProps> = ({
  text,
  isExpectedToWrap,
  ...restProps
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const opacityRef = useRef(isExpectedToWrap ? 0 : 1);

  useEffect(() => {
    if (!ref.current) return;
    measure('balanceText', () => balanceText([ref.current!]));
    ref.current.style.opacity = '1';
  }, [ref.current]);

  return (
    <Text {...restProps}>
      <div
        ref={ref}
        style={{ opacity: opacityRef.current, transition: 'opacity 150ms' }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Text>
  );
};

export default BalancedText;
