import { useMemo } from 'react';

import { ItemContext } from '~/app/components/ItemPage/types';
import { SelectedCustomPage } from '~/app/lib/store/types';

const useCustomPageContext = ({
  customPage,
  originalItemContext,
}: {
  customPage?: SelectedCustomPage;
  originalItemContext?: ItemContext;
}) => {
  const config = customPage?.config;
  const customLayoutResolved = config?.layout;
  const addonsResolved = config?.addons || {};

  return useMemo<ItemContext | undefined>(
    () =>
      customPage && {
        presets: {},

        data: {
          item: customPage,
          custom: config?.objects,
        },

        layout: {
          main: customLayoutResolved?.main || [],
        },

        config,

        addons: addonsResolved,
        originalItemContext,
      },
    [customPage, originalItemContext]
  );
};

export default useCustomPageContext;
