import { deleteAlbumApi } from '~/app/lib/songwhipApi/albums';
import { ItemTypes } from '~/types';

import { deleteItemSuccess } from '../../lib/deleteItemSuccess';
import { Dispatch } from '../../types';

const deleteAlbumAction =
  ({ albumId }: { albumId: number }) =>
  async (dispatch: Dispatch) => {
    const album = await deleteAlbumApi({ albumId });

    dispatch(
      deleteItemSuccess({
        id: albumId,
        type: ItemTypes.ALBUM,
        path: album.path,
      })
    );
  };

export default deleteAlbumAction;
