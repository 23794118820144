import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';
import { patchAlbumApi } from '~/app/lib/songwhipApi/albums';

import { fetchAlbumError, fetchAlbumStart } from './fetchAlbum';
import { Dispatch } from '../../types';

export const patchAlbum =
  (params: Parameters<typeof patchAlbumApi>[0]) =>
  async (dispatch: Dispatch) => {
    const { albumId } = params;

    try {
      dispatch(fetchAlbumStart(albumId));
      const album = await patchAlbumApi(params);
      dispatchFetchItemSuccess(dispatch, album);
    } catch (error) {
      dispatch(fetchAlbumError(albumId, error));
      throw error;
    }
  };
